// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-development-js": () => import("./../../../src/pages/app-development.js" /* webpackChunkName: "component---src-pages-app-development-js" */),
  "component---src-pages-artificial-intelligence-js": () => import("./../../../src/pages/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-artificial-intelligence-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-management-js": () => import("./../../../src/pages/case-management.js" /* webpackChunkName: "component---src-pages-case-management-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-analysis-js": () => import("./../../../src/pages/data-analysis.js" /* webpackChunkName: "component---src-pages-data-analysis-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../../../src/pages/digital-transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-dino-js": () => import("./../../../src/pages/dino.js" /* webpackChunkName: "component---src-pages-dino-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-c-2-s-js": () => import("./../../../src/pages/project/c2s.js" /* webpackChunkName: "component---src-pages-project-c-2-s-js" */),
  "component---src-pages-project-cc-js": () => import("./../../../src/pages/project/cc.js" /* webpackChunkName: "component---src-pages-project-cc-js" */),
  "component---src-pages-project-change-js": () => import("./../../../src/pages/project/change.js" /* webpackChunkName: "component---src-pages-project-change-js" */),
  "component---src-pages-project-cooperazione-digitale-js": () => import("./../../../src/pages/project/cooperazione-digitale.js" /* webpackChunkName: "component---src-pages-project-cooperazione-digitale-js" */),
  "component---src-pages-project-cpa-web-platform-js": () => import("./../../../src/pages/project/cpa-web-platform.js" /* webpackChunkName: "component---src-pages-project-cpa-web-platform-js" */),
  "component---src-pages-project-cri-js": () => import("./../../../src/pages/project/cri.js" /* webpackChunkName: "component---src-pages-project-cri-js" */),
  "component---src-pages-project-crise-oubliee-tchad-js": () => import("./../../../src/pages/project/crise-oubliee-tchad.js" /* webpackChunkName: "component---src-pages-project-crise-oubliee-tchad-js" */),
  "component---src-pages-project-csvref-js": () => import("./../../../src/pages/project/csvref.js" /* webpackChunkName: "component---src-pages-project-csvref-js" */),
  "component---src-pages-project-development-data-platform-js": () => import("./../../../src/pages/project/development-data-platform.js" /* webpackChunkName: "component---src-pages-project-development-data-platform-js" */),
  "component---src-pages-project-drr-js": () => import("./../../../src/pages/project/drr.js" /* webpackChunkName: "component---src-pages-project-drr-js" */),
  "component---src-pages-project-foreseeing-inclusion-js": () => import("./../../../src/pages/project/foreseeing-inclusion.js" /* webpackChunkName: "component---src-pages-project-foreseeing-inclusion-js" */),
  "component---src-pages-project-formazionesviluppo-js": () => import("./../../../src/pages/project/formazionesviluppo.js" /* webpackChunkName: "component---src-pages-project-formazionesviluppo-js" */),
  "component---src-pages-project-ibva-js": () => import("./../../../src/pages/project/ibva.js" /* webpackChunkName: "component---src-pages-project-ibva-js" */),
  "component---src-pages-project-irhis-js": () => import("./../../../src/pages/project/irhis.js" /* webpackChunkName: "component---src-pages-project-irhis-js" */),
  "component---src-pages-project-kalms-js": () => import("./../../../src/pages/project/kalms.js" /* webpackChunkName: "component---src-pages-project-kalms-js" */),
  "component---src-pages-project-kap-tanzania-kenya-js": () => import("./../../../src/pages/project/kap-tanzania-kenya.js" /* webpackChunkName: "component---src-pages-project-kap-tanzania-kenya-js" */),
  "component---src-pages-project-lis-eis-js": () => import("./../../../src/pages/project/lis-eis.js" /* webpackChunkName: "component---src-pages-project-lis-eis-js" */),
  "component---src-pages-project-mah-js": () => import("./../../../src/pages/project/mah.js" /* webpackChunkName: "component---src-pages-project-mah-js" */),
  "component---src-pages-project-mapping-response-covid-js": () => import("./../../../src/pages/project/mapping-response-covid.js" /* webpackChunkName: "component---src-pages-project-mapping-response-covid-js" */),
  "component---src-pages-project-para-todas-as-crianca-js": () => import("./../../../src/pages/project/para-todas-as-crianca.js" /* webpackChunkName: "component---src-pages-project-para-todas-as-crianca-js" */),
  "component---src-pages-project-picaps-js": () => import("./../../../src/pages/project/picaps.js" /* webpackChunkName: "component---src-pages-project-picaps-js" */),
  "component---src-pages-project-quelimane-agricola-js": () => import("./../../../src/pages/project/quelimane-agricola.js" /* webpackChunkName: "component---src-pages-project-quelimane-agricola-js" */),
  "component---src-pages-project-soleterre-js": () => import("./../../../src/pages/project/soleterre.js" /* webpackChunkName: "component---src-pages-project-soleterre-js" */),
  "component---src-pages-project-test-and-treat-js": () => import("./../../../src/pages/project/test-and-treat.js" /* webpackChunkName: "component---src-pages-project-test-and-treat-js" */),
  "component---src-pages-project-tu-6-scuola-js": () => import("./../../../src/pages/project/tu6scuola.js" /* webpackChunkName: "component---src-pages-project-tu-6-scuola-js" */),
  "component---src-pages-project-water-trucking-software-js": () => import("./../../../src/pages/project/water-trucking-software.js" /* webpackChunkName: "component---src-pages-project-water-trucking-software-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-social-balance-monitoring-evaluation-js": () => import("./../../../src/pages/social-balance-monitoring-evaluation.js" /* webpackChunkName: "component---src-pages-social-balance-monitoring-evaluation-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

